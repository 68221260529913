<template>
  <div class="mb-4 card">
    <div class="card-body">
      <div v-if="testResults">
        <div class="row">
          <div class="col">
            <h4 class="card-title">Tech Stack</h4>
          </div>
        </div>
        <div class="row row-cols-1">
          <div class="col" v-for="(category, categoryName) in groupedTechnologies" :key="categoryName">
            <div class="card mb-3">
              <div class="card-header lead">{{ categoryName }}</div>
              <div class="card-body d-flex flex-wrap">
                <!-- Flex container to allow technologies to wrap horizontally -->
                <div
                  v-for="tech in detectedTechnologies[categoryName]"
                  :key="tech.name"
                  class="tech-item d-flex flex-column align-items-center justify-content-center"
                >
                  <img
                    v-if="tech.icon"
                    :src="require(`@/assets/logos/${tech.icon}`)"
                    :alt="tech.name"
                    class="tech_logo"
                  />
                  <p class="lead text-center align-bottom">{{ tech.name }}</p>
                </div>
                <div v-if="!detectedTechnologies[categoryName].length" class="tech-item d-flex flex-column align-items-center justify-content-center">
                  <p class="text-center"><i class="bi text-warning fs-1 bi-question-octagon-fill"></i></p>
                  <p class="lead text-center">No {{ categoryName }} detected</p>
                </div>
                
              </div>
            </div>
          </div>
          <div class="col">
          <div class="card mb-3">
              <div class="card-header lead">Web Application Firewall</div>
              <div class="card-body d-flex ">
                <!-- Flex container to allow technologies to wrap horizontally -->
                <div v-if="testResults.wafResult.detected" class="tech-item d-flex flex-column align-items-center justify-content-center">
            
                  <p class="lead text-center">{{ testResults.wafResult.wafName }}</p>

                </div>
                
                
                <div v-if="!testResults.wafResult.detected" class="tech-item d-flex flex-column align-items-center justify-content-center">
                  <p class="text-center"><i class="bi text-warning fs-1 bi-question-octagon-fill"></i></p>
                  <p class="lead text-center">No Web Application Firewall detected</p>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['testResults'],
  data() {
    return {
      techStack: {
        '1Password': "1password.png",
        Adobe: "adobe.png",
        Ahrefs: "ahrefs.png",
        Airtable: "airtable.png",
        Akamai: "akamai.png",
        AliBaba: "alibaba.png",
        Amazon: "amazon.png",
        Anodot: "anodot.png",
        Apple: "apple.png",
        Atlassian: "atlassian.png",
        'Atlassian Statuspage': "statuspage.png",
        Attaxion: "attaxion.svg",
        Autodesk: "autodesk.svg",
        AWeber: "aweber.png",
        Barracuda: "barracuda.png",
        BeautifulAI: "beautifulai.webp",
        Bitrise: "bitrise.png",
        Bitwarden: "bitwarden.png",
        Botify: "botify.png",
        Box: "box.png",
        Brevo: "brevo.webp",
        BrowserStack: "browserstack.png",
        BugCrowd: "bugcrowd.png",
        Calendly: "calendly.svg",
        Canva: "canva.png",
        Cisco: "cisco.png",
        'Cisco Duo': "duo.png",
        Citrix: "citrix.png",
        ClickFunnels: "clickfunnels.png",
        CloudBees: "cloudbees.png",
        Cloudflare: "cloudflare.png",
        'Customer.io': "customerio.svg",
        Detectify: "detectify.svg",
        Docker: "docker.svg",
        DocuSign: "docusign.png",
        Dropbox: "dropbox.png",
        Drift: "drift.png",
        Dynatrace: "dynatrace.png",
        Emma: "emma.png",
        Fastly: "fastly.svg",
        FastMail: "fastmail.png",
        Favro: "favro.svg",
        Figma: "figma.png",
        Flexera: "flexera.svg",
        'Forward Email': "forwardemail.png",
        FrontEgg: "frontegg.png",
        GlobalSign: "globalsign.png",
        GoCardless: "gocardless.png",
        GoDaddy: "godaddy.png",
        'Google Workspace': "googleworkspace.png",
        Gradle: "gradle.png",
        HackerOne: "hackerone.png",
        Happeo: "happeo.png",
        HashiCorp: "hashicorp.svg",
        Heroku: "heroku.svg",
        IBM: "ibm.png",
        Infoblox: "infoblox.png",
        Intercom: "intercom.png",
        InvisionApp: "invisionapp.png",
        Jamf: "jamf.png",
        Kayak: "kayak.svg",
        Keybase: "keybase.png",
        Klaviyo: "klaviyo.png",
        KnowBe4: "knowbe4.svg",
        Krisp: "krisp.svg",
        Kubernetes: "kubernetes.svg",
        "Let's Encrypt": "letsencrypt.png",
        LiveRamp: "liveramp.png",
        Loaderio: "loaderio.png",
        LogMeIn: "logmein.png",
        Loom: "loom.png",
        Lucid: "lucid.svg",
        MailChannels: "mailchannels.png",
        MailChimp: "mailchimp.jpg",
        Mailgun: "mailgun.png",
        Mailjet: "mailjet.png",
        'Microsoft Azure': "azure.png",
        'Microsoft Dynamics': "dynamics365.png",
        'Microsoft M365': "microsoft.webp",
        Mimecast: "mimecast.png",
        Miro: "miro.png",
        Mixpanel: "mixpanel.png",
        'Monday.com': "monday.png",
        MongoDB: "mongodb.png",
        Namecheap: "namecheap.png",
        'New Relic': "newrelic.png",
        Notion: "notion.png",
        Okta: "okta.png",
        OneTrust: "onetrust.png",
        OpenAI: "openai.png",
        Oracle: "oracle.png",
        'Paloalto Networks': "paloalto.png",
        Parallels: "parallels.png",
        Parsec: "parsec.svg",
        Pendo: "pendo.svg",
        Pexip: "pexip.png",
        Postman: "postman.avif",
        ProofPoint: "proofpoint.png",
        ProtonMail: "protonmail.png",
        Rackspace: "rackspace.jpg",
        Reachdesk: "reachdesk.webp",
        Rhino3D: "rhino3d.jpeg",
        Rippling: "rippling.png",
        Sage: "sage.png",
        Salesforce: "salesforce.png",
        'Salesforce Marketing Cloud': "salesforcemarketingcloud.png",
        'Schneider Electric': "schneider.png",
        Segment: "segment.png",
        SendGrid: "sendgrid.png",
        Sendinblue: "sendinblue.png",
        Slack: "slack.png",
        SmartBear: "smartbear.png",
        Smartsheet: "smartsheet.png",
        Sonatype: "sonatype.svg",
        Sophos: "sophos.png",
        Spiceworks: "spiceworks.webp",
        Stripe: "stripe.png",
        'Symantec MDM': "symantec.webp",
        TeamViewer: "teamviewer.png",
        Trello: "trello.png",
        Twilio: "twilio.png",
        VMWare: "vmware.svg",
        Webex: "webex.png",
        Whimsical: "whimsical.png",
        Wrike: "wrike.png",
        Yandex: "yandex.png",
        Yahoo: "yahoo.png",
        Zapier: "zapier.png",
        Zendesk: "zendesk.png",
        Zoho: "zoho.png",
        Zoom: "zoom.png",
        // Add other technologies with their corresponding icons here...

        // CMS Platforms
        Blogger: "blogger.png",
        Duda: "duda.svg",
        Drupal: "drupal.png",
        Ghost: "ghost.png",
        HubSpot: "hubspot.png",
        Joomla: "joomla.png",
        Magento: "magent.png",
        ModX: "modx.png",
        OctoberCMS: "octobercm.webp",
        OpenCart: "opencart.png",
        Prestashop: "prestashop.png",
        Shopify: "shopify.png",
        Sitecore: "sitecore.png",
        Squarespace: "squarespace.png",
        TYPO3: "typo3.png",
        Umbraco: "umbraco.png",
        Webflow: "webflow.svg",
        Wix: "wix.png",
        WordPress: "wordpress.png",
        ZenCart: "zencart.png",

        // Javascript Frameworks
        Angular: "angular.png",
        AMP: "amp.png",
        Aurelia: "aurelia.png",
        Backbone: "backbone.png",
        Ember: "ember.svg",
        Emotion: "emotion.png",
        Meteor: "meteor.png",
        Next: "nextjs.svg",
        Nuxt: 'nuxt.svg',
        React: "react.png",
        Sapper: "sapper.png",
        StyledComponents: "styledcomponents.png",
        Svelte: "svelte.svg",
        Vue: "vue.png",

        // CSS Frameworks
        AntDesign: "antdesign.png",
        Bootstrap: "bootstrap.png",
        Bulma: "bulma.png",
        FomanticUI: "fomanticui.png",
        Foundation: "foundation.png",
        Materliaze: "materialize.png",
        MUI: "mui.png",
        PureCSS: "purecss.png",
        Skeleton: "skeleton.png",
        Spectre: "spectre.png",
        Tachyons: "tachyons.png",
        Tailwind: "tailwind.png",
        UIKit: "uikit.png",
       
        //Javascript Libraries
        Anime: "anime.png",
        Axios: "axios.png",
        Chart: "chart.png",
        D3: "d3.png",
        GSAP: "gsap.png",
        Handlebars: "handlebars.png",
        jQuery: "jquery.png",
        Leaflet: "leaflet.png",
        Lodash: "lodash.png",
        Moment: "moment.png",
        Pixi: 'pixi.svg',
        Polymer: 'polymer.png',
        Preact: 'preact.png',
        Redux: 'redux.png',
        RequireJS: 'requirejs.png',
        RxJS: "rxjs.png",
        Three: "three.png",
        Underscore: "underscore.png",
        Zepto: 'zepto.png',
      },

      groupedTechnologies: {
        Technologies: [ "1Password", "Adobe", "Ahrefs", "Airtable", "AliBaba", "Akamai", "Amazon", "Anodot", "Apple", "Atlassian", "Atlassian Statuspage", "Attaxion", "Autodesk", "AWeber", 
                        "Barracuda", "BeautifulAI", "Bitrise", "Bitwarden", "Botify", "Box", "Brevo", "BrowserStack", "Bugcrowd",
                        "Calendly", "Canva", "Cisco", "Cisco Duo", "Citrix", "ClickFunnels", "CloudBees", "Cloudflare", "Customer.io",
                        "Detectify", "Docker", "DocuSign", "Dropbox", "Drift", "Dynatrace",
                        "Emma",
                        "Fastly", "FastMail", "Favro", "Figma", "Flexera", "Forward Email", "FrontEgg",
                        "GlobalSign", "GoCardless", "GoDaddy", "Google Workspace", "Gradle", 
                        "Happeo", "Heroku",
                        "IBM", "Infoblox", "Intercom", "InvisionApp",
                        "Jamf",
                        "Kayak", "Keybase", "Klaviyo", "KnowBe4", "Krisp", "Kubernetes",
                        "Let's Encrypt", "LiveRamp", "Loaderio", "LogMeIn", "Loom", "Lucid",
                        "MailChannels", "MailChimp", "Mailgun", "Mailjet", "Microsoft Azure", "Microsoft Dynamics", "Microsoft M365", "Mimecast", "Miro", "Mixpanel", "Monday.com", "MongoDB",
                        "Namecheap", "New Relic", "Notion",
                        "Okta", "OneTrust", "OpenAI", "Oracle",
                        "Paloalto Networks", "Parrallels", "Parsec", "Pendo", "Pexip", "Postman", "Proofpoint", "Protonmail",
                        "Rackspace", "Reachdesk", "Rippling", "Rhino3D", 
                        "Sage", "Salesforce", "Salesforce Marketing Cloud", "Schneider Electric", "Segment", "Sendgrid", "Sendinblue", "Slack", "SmartBear", "Smartsheet", "Sonatype", "Sophos", "Spiceworks", "Stripe", "Symantec MDM",
                        "TeamViewer", "Trello", "Twilio",
                        "VMWare",
                        "Webex", "Whimsical", "Wrike",
                        "Yandex", "Yahoo",
                        "Zapier", "Zendesk", "Zoho", "Zoom"],
        "CMS Frameworks": ["Blogger", "Duda", "Drupal", "Ghost", "HubSpot",  "Joomla", "Magento", "ModX", "OctoberCMS", "OpenCart", "Prestashop", "Shopify", "Sitecore", "Squarespace", "Typo3", "Umbreco", "Webflow", "Wix", "WordPress", "ZenCart"],
        "Javascript Frameworks": ["Angular", "AMP", "Aurelia", "Backbone", "Ember", "Emotion", "Meteor", "Next", "Nuxt", "React", "Sapper", "StyledComponents", "Svelte", "Vue"],
        "CSS Frameworks": ["AntDesign", "Bootstrap", "Bulma", "FomanticUI", "Foundation", "Materialize", "MUI", "PureCSS", "Skeleton", "Spectre", "Tachyons", "Tailwind", "UIKit" ],
        "Javascript Libraries": ["Anime", "Axios", "Chart", "D3", "GSAP", "Handlebars", "jQuery", "Leaflet", "Lodash", "Moment", "Pixi", "Polymer", "Preact", "Redux", "RequireJS", "RxJS", "Three", "Underscore", "Zepto" ],
      }
    };
  },
  computed: {
    detectedTechnologies() {
      const detected = {};
      
      // Initialize detected object with category keys
      for (const categoryName of Object.keys(this.groupedTechnologies)) {
        detected[categoryName] = [];
      }

      // Populate detected technologies based on test results
      for (const [techName, icon] of Object.entries(this.techStack)) {
        const category = Object.entries(this.groupedTechnologies).find(([_, techList]) => techList.includes(techName));
        
        if (category && this.testResults?.techStack?.[techName]) {
          const [categoryName] = category;
          detected[categoryName].push({ name: techName, icon });
        }
      }

      return detected;
    }
  }
};
</script>

<style>

.tech_logo {
  max-width: 200px;
  max-height: 100px;
  margin-bottom: 10px; /* Adds space between the image and the text */
}

.tech-item {
  flex: 1 0 100px; /* Makes items flex-grow and flex-shrink while maintaining a minimum width of 100px */
  text-align: center;
  margin: 10px; /* Adds spacing between technology items */
  width: auto; /* Ensures items can shrink or grow with screen size */
}

@media (min-width: 576px) {
  /* Adjusts the minimum width for larger screens */
  .tech-item {
    flex: 1 0 150px;
  }
}

@media (min-width: 768px) {
  .tech-item {
    flex: 1 0 200px;
  }
}
</style>

