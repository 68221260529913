// src/main.js
import 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createApp } from 'vue';
import { VueFire, VueFireAuth } from 'vuefire';
import App from './App.vue';
import { auth, db } from './firebase'; // Ensure this path is correct
import router from './router';

const app = createApp(App);

app.use(router);

app.use(VueFire, {
    firebaseApp: auth.app, // Ensure the correct firebaseApp instance is provided
    modules: [
        VueFireAuth(),
    ],
    firestore: {
        db,
    }
});

app.mount('#app');
