<template>
    <div class="authpage">
        <div class="container d-flex align-items-center justify-content-center" style="min-height: 100vh">
            <div class="w-100" style="max-width: 400px">
                <div class="card">
                    <div class="card-body">
                        <h2 class="text-center mb-4">
                            <img src="../assets/ir8_logo.svg" alt="IR⁸ Labs Logo" class="d-inline-block align-baseline"
                                height="80" />
                        </h2>
                        <div v-if="error" class="alert alert-danger">{{ error }}</div>
                        <div v-if="success" class="alert alert-success">{{ success }}</div>
                        <form @submit.prevent="loginUser">
                            <div class="mb-3">
                                <h1 class="h3 text-center mb-3 font-weight-normal">Please sign in</h1>
                                <input type="email" class="form-control" id="email" v-model="email"
                                    placeholder="Enter email" required />
                            </div>
                            <div class="mb-3">
                                <input type="password" class="form-control" id="password" v-model="password"
                                    placeholder="Enter password" required />
                            </div>
                            <button :disabled="loading" class="btn btn-primary w-100" type="submit">
                                Log In
                            </button>
                            <div class="w-100 text-center mt-4">
                                Need an account? <router-link to="/signup">Signup!</router-link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { signInWithEmailAndPassword } from 'firebase/auth';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { auth } from '../firebase'; // Ensure firebase is configured

export default {
    setup() {
        const email = ref('');
        const password = ref('');
        const error = ref('');
        const success = ref('');
        const loading = ref(false);
        const router = useRouter();

        const loginUser = async () => {
            loading.value = true;
            error.value = '';
            success.value = '';

            try {
                // Sign in with email and password
                await signInWithEmailAndPassword(auth, email.value, password.value);
                success.value = 'Successfully logged in!';
                router.push('/'); // Redirect to the account page or another appropriate page
            } catch (err) {
                error.value = err.message;
            } finally {
                loading.value = false;
            }
        };

        return {
            email,
            password,
            error,
            success,
            loading,
            loginUser,
        };
    },
};
</script>

<style scoped>
.container {
    min-height: 100vh;
}
.app {
    padding: -150px;
}
</style>