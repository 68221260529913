<template>
  <div id="app">
    <nav class="navbar navbar-dark navbar-expand-lg text-white border-bottom border-warning border-5" style="background: #343A40">
      <div class="container">
        <RouterLink class="navbar-brand text-white fw-light" aria-current="page" to="/">
          <img src="../assets/ir8_logo.svg" alt="IR⁸ Labs Logo" class="d-inline-block align-top me-2" width="60" />
          <span class="display-6">Foreshadow</span>
      </RouterLink>
        <div class="me-auto"></div><!-- Added to pad out nav bar DO NOT REMOVE -->
        <div class="btn-group">
          <button class="btn btn-link nav-link py-2 px-0 px-lg-2 dropdown-toggle d-flex align-items-center" id="bd-theme" type="button" aria-expanded="false" data-bs-toggle="dropdown">
            <i class="fs-2 p-3 align-middle text-warning bi bi-person-circle"></i>
          </button>
          <ul class="dropdown-menu dropdown-menu-lg-end">
            <li class="nav-item" v-if="userRole === 'admin'">
              <RouterLink class="dropdown-item" aria-current="page" to="/admin">Admin</RouterLink>
            </li>
            <li class="nav-item">
              <RouterLink class="dropdown-item" aria-current="page" to="/account">Account</RouterLink>
            </li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" @click="signOut" href="#">Logout</a></li>
          </ul>
        </div>
      </div>
    </nav>
    <router-view />
  </div>
</template>
<script>
import { signOut as firebaseSignOut, getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { db } from '../firebase'; // Adjust the import based on your Firebase configuration

export default {
  name: 'HeaderBar',
  setup() {
    const user = ref(null);
    const userRole = ref(null); // Store the user role
    const auth = getAuth();
    const router = useRouter();

    onMounted(async () => {
      user.value = auth.currentUser;

      if (user.value) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.value.uid));
          if (userDoc.exists()) {
            userRole.value = userDoc.data().role || 'user'; // Default to 'user' if no role is found
          }
        } catch (error) {
          console.error("Error fetching user role:", error);
        }
      }
    });

    const signOut = () => {
      firebaseSignOut(auth)
        .then(() => {
          router.push('/login');
        })
        .catch((error) => {
          alert(`Error signing out: ${error.message}`);
        });
    };

    return {
      signOut,
      userRole, // Expose the user role to the template
    };
  },
};
</script>