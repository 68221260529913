<template>
  <div class="app">
    <router-view />
  </div>
  </template>
  
  <script>
  export default {
    name: 'App'
  };
  </script>
  
  <style>
  /* Add some custom styling if necessary */
  .authpage {
    background-color: #343A40;
  }
 


  </style>
  