<template>
    <b-container>
        <b-row class="justify-content-md-center">
            <b-col md="4">
                <b-card title="Forgot Password">
                    <b-form @submit.prevent="resetPassword">
                        <b-form-group label="Email" label-for="email">
                            <b-form-input id="email" v-model="email" type="email" required></b-form-input>
                        </b-form-group>
                        <b-button type="submit" variant="primary">Reset Password</b-button>
                        <b-link href="/login">Login</b-link>
                        <b-link href="/signup">Sign Up</b-link>
                    </b-form>
                </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { auth } from '../firebase';
import { ref } from 'vue';

export default {
    setup() {
        const email = ref('');

        const resetPassword = async () => {
            try {
                await auth.sendPasswordResetEmail(email.value);
                alert('Password reset email sent.');
                this.$router.push('/login');
            } catch (error) {
                console.error(error.message);
            }
        };

        return {
            email,
            resetPassword
        };
    }
};
</script>