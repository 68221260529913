<template>
  <div class="input-group my-5 input-group-lg">
    <input 
      type="text" 
      class="form-control border-left-0" 
      v-model="searchQuery" 
      placeholder="Search company..." 
      aria-label="Search company" 
      aria-describedby="search-button"
    >
  </div>

  <div class="card my-5">
    <div class="card-body">
      <div class="container">
        <div v-if="filteredCompanies.length > 0" class="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
          <div v-for="(company, index) in filteredCompanies" :key="company.id" class="col">
            <RouterLink 
              :to="{ name: 'CompanyDetails', params: { companyDomain: company.companyDomain } }" 
              class="link-underline-danger link-underline-opacity-0"
            >
              <div class="card m-2">
                <img 
                  :src="company.companyAvatar"  
                  class="card-img-top"  
                  :alt="company.companyName + ' Logo'" 
                  @error="handleError(index, true)"
                />
               
                
                <div class="card-footer">
                  <p class="card-text text-truncate link-underline link-underline-opacity-0">{{ company.companyName }}</p>
                </div>
              </div>
            </RouterLink>
          </div>
        </div>
        <div v-else-if="companies.length > 0" class="p-5 m-5 d-flex justify-content-center">
          <p>No matching companies found.</p>
        </div>
        <div v-else class="p-5 m-5 d-flex justify-content-center">
          <div class="spinner-border text-warning" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  
<script>
import { getAuth } from 'firebase/auth';
import { collection, doc, getDoc, getDocs, orderBy, query, where } from 'firebase/firestore';
import { computed, onMounted, ref, watch } from "vue";
import { db } from '../firebase';

export default {
  name: 'SearchCompany',
  setup() {
    const companies = ref([]);
    const user = ref(null);
    const followedCompanies = ref([]);
    const searchQuery = ref('');
    const isSearching = ref(false);
    const auth = getAuth();

    const fetchFollowedCompanies = async () => {
      user.value = auth.currentUser;

      if (user.value) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.value.uid));
          if (userDoc.exists()) {
            const followedCompanyIds = userDoc.data().followedCompanies || [];
            if (followedCompanyIds.length > 0) {
              const q = query(
                collection(db, 'companies'), 
                where('showRecord', '==', true), 
                where('__name__', 'in', followedCompanyIds), 
                orderBy('companyName'),
                
              );
              const querySnapshot = await getDocs(q);
              followedCompanies.value = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            }
          }
        } catch (error) {
          console.error("Error fetching user followed companies:", error);
        }
      }
    };


    const fetchAllCompanies = async () => {
      const q = query(
        collection(db, 'companies'), 
        where('showRecord', '==', true), 
        orderBy('companyName'),
       
        
      );
      const querySnapshot = await getDocs(q);
      companies.value = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    };

    const handleError = (index, useFiltered = false) => {
  console.log('X Image not loading, lets try something else.');

  const sourceArray = useFiltered ? filteredCompanies.value : companies.value;

  // Ensure the company at the specified index exists in the selected array
  if (sourceArray[index]) {
    console.log('working out what to do.', sourceArray[index]);

    // Update the companyAvatar for the selected company
    sourceArray[index].companyAvatar = sourceArray[index].companyLogo || '../assets/ir8_logo.svg';

    console.log(sourceArray[index].companyAvatar);

    // Find the image element and update its source
    const imgElement = document.querySelectorAll('.card-img-top')[index];
    if (imgElement) {
      imgElement.src = sourceArray[index].companyAvatar;
    }
  }
};






    const filteredCompanies = computed(() => {
      const source = isSearching.value ? companies.value : followedCompanies.value;
      if (!searchQuery.value) {
        return source;
      }
      const lowercaseQuery = searchQuery.value.toLowerCase();
      return source.filter(company => 
        company.companyName.toLowerCase().includes(lowercaseQuery)
      );
    });

    watch(searchQuery, (newQuery) => {
      if (newQuery) {
        isSearching.value = true;
        fetchAllCompanies();
      } else {
        isSearching.value = false;
      }
    });

    onMounted(fetchFollowedCompanies);

    return { 
      companies, 
      followedCompanies, 
      searchQuery, 
      handleError, 
      filteredCompanies 
    };
  }
};
</script>
  
  <style>
  .logo_card {
    width: 128px;
    height: 128px;
    margin-top: -100px !important;
    margin: auto;
  }
  .info_card {
    margin-top: 100px;
  }
  
  span.input-group-text {
      background-color: #fff;
  
  }
  /* .form-control:focus {
  border-color: #FFF;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 8px #393b3d;
} */
  </style>