<template>
  <HeaderBar></HeaderBar>
  <div class="container">
    <div class="card my-5">
      <div class="card-body">
        <div class="container">
          <h1>Account Details</h1>

          <div v-if="user">
            <form @submit.prevent="updateUserDetails">
              <div class="mb-3">
                <label for="displayName" class="form-label">Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="displayName"
                  v-model="name"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="email" class="form-label">Email address</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  v-model="email"
                  required
                />
              </div>
            
              <button class="btn btn-primary" type="submit">Update Account</button>
              <button class="btn btn-secondary myFloat_Right" @click="promptPasswordChange">Change Password</button>
         
            </form>
            
          </div>
          <div v-else>
            <p>Loading account details...</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EmailAuthProvider, signOut as firebaseSignOut, getAuth, reauthenticateWithCredential, updateEmail, updatePassword, updateProfile } from 'firebase/auth';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import HeaderBar from '../components/HeaderBar';

export default {
  name: 'AccountDetails',
  components: {
    HeaderBar,
  },
  setup() {
    const user = ref(null);
    const displayName = ref('');
    const email = ref('');
    const auth = getAuth();
    const router = useRouter();

    onMounted(() => {
      user.value = auth.currentUser;
      if (user.value) {
        displayName.value = user.value.displayName || '';
        email.value = user.value.email || '';
      }
    });

    const updateUserDetails = async () => {
      try {
        if (user.value) {
          await updateProfile(user.value, { displayName: displayName.value });
          await updateEmail(user.value, email.value);
          alert('Account details updated successfully!');
        }
      } catch (error) {
        if (error.code === 'auth/requires-recent-login') {
          reauthenticateUser();
        } else {
          alert(`Error: ${error.message}`);
        }
      }
    };

    const reauthenticateUser = () => {
      const currentPassword = prompt('Enter your current password:');
      if (currentPassword && user.value) {
        const credential = EmailAuthProvider.credential(user.value.email, currentPassword);
        reauthenticateWithCredential(user.value, credential)
          .then(() => {
            updateUserDetails();
          })
          .catch((error) => {
            alert(`Error: ${error.message}`);
          });
      }
    };

    const promptPasswordChange = () => {
      const newPassword = prompt('Enter your new password:');
      if (newPassword && user.value) {
        updatePassword(user.value, newPassword)
          .then(() => {
            alert('Password updated successfully!');
          })
          .catch((error) => {
            if (error.code === 'auth/requires-recent-login') {
              reauthenticateUser(newPassword);
            } else {
              alert(`Error: ${error.message}`);
            }
          });
      }
    };

    const signOut = () => {
      firebaseSignOut(auth)
        .then(() => {
          router.push('/login');
        })
        .catch((error) => {
          alert(`Error signing out: ${error.message}`);
        });
    };

    return {
      user,
      displayName,
      email,
      updateUserDetails,
      promptPasswordChange,
      signOut,
    };
  },
};
</script>

<style scoped>
/* Add your styles here if needed */
.myFloat_Right {
    float: right;
}
</style>
