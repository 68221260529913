<template>
    <div class="authpage">
        <div class="container d-flex align-items-center justify-content-center" style="min-height: 100vh">
            <div class="w-100" style="max-width: 400px">
                <div class="card">
                    <div class="card-body">
                        <h2 class="text-center mb-4">
                            <img src="../assets/ir8_logo.svg" alt="IR⁸ Labs Logo" class="d-inline-block align-baseline"
                                height="80" />
                        </h2>
                        <div v-if="error" class="alert alert-danger">{{ error }}</div>
                        <form @submit.prevent="signUpUser">
                            <div class="mb-3">
                                <h1 class="h3 text-center mb-3 font-weight-normal">Please sign up</h1>
                                <input type="email" class="form-control" id="email" v-model="email"
                                    placeholder="Enter email" required />
                            </div>
                            <div class="mb-3">
                                <input type="password" class="form-control" id="password" v-model="password"
                                    placeholder="Enter password" required />
                            </div>
                            <div class="mb-3">
                                <input type="text" class="form-control" id="signupCode" v-model="signupCode"
                                    placeholder="Enter signup code" required />
                            </div>
                            <button :disabled="loading" class="btn btn-primary w-100" type="submit">
                                Sign Up
                            </button>
                            <div class="w-100 text-center mt-4">
                                Already have an account? <router-link to="/login">Log In</router-link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { auth, db } from '../firebase'; // Ensure firebase is configured

export default {
    setup() {
        const email = ref('');
        const password = ref('');
        const signupCode = ref('');
        const error = ref('');
        const loading = ref(false);
        const router = useRouter();

        const signUpUser = async () => {
            loading.value = true;
            error.value = '';

            try {
                // Verify signup code
                const docRef = doc(db, 'signupCodes', signupCode.value);
                const docSnap = await getDoc(docRef);

                if (!docSnap.exists()) {
                    throw new Error('Invalid signup code');
                }

                // Create user with email and password
                const userCredential = await createUserWithEmailAndPassword(auth, email.value, password.value);
                const user = userCredential.user;

                // Add user to the "Users" collection
                await setDoc(doc(db, 'users', user.uid), {
                    name: '', // You might want to capture the user's name as well
                    email: user.email,
                    role: 'user', // Default role, adjust as necessary
                    createdAt: serverTimestamp(),
                });

                alert('Sign-up successful! Please log in.');
                router.push('/login');
            } catch (err) {
                error.value = err.message;
            } finally {
                loading.value = false;
            }
        };

        return {
            email,
            password,
            signupCode,
            error,
            loading,
            signUpUser,
        };
    },
};
</script>