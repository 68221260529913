<template>
    
    <div style="margin-bottom: 30px;"></div>
        <div class="card">
          <div class="card-body">
            <div v-if="testResults">
            <div class="row">
                <div class="col">   
                    <h4 class="card-title">Brand Security</h4>
                </div>
                <div class="col">
                    <h3 class="float-end">
                        <span class="badge bg-secondary">--%</span>
                    </h3>
                </div>
            </div>
            
            <div class="row px-3">
                <table class="table ">
                    <tbody>
                        <!-- SPF Results -->
                        <!-- Security.txt Test -->
                        <tr>
                            <th scope="row">
                                <i v-if="testResults.securityTextResults.result == 'passed'" class="h3 text-success bi bi-check-circle-fill"></i>
                                <i v-if="testResults.securityTextResults.result == 'failed'" class="h3 text-danger bi bi-exclamation-circle-fill"></i>
                            </th>
                            <td class="lead">Security.txt available for domain</td>
                            <td>
                                <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#secTxtTest1" role="button" aria-expanded="false" aria-controls="secTxtTest1"></i>
                            </td>
                        </tr>
                        <tr class="collapse multi-collapse" id="secTxtTest1">
                            <td colspan="3"> 
                                <p class="blockquote-footer mt-2 ">{{ testResults.securityText }}</p>  
                                <p class="text-muted ms-3">
                                    A `security.txt` file is a standardised file placed in the `.well-known` directory of a website's root directory. It provides essential contact information and guidelines for security researchers or ethical hackers who discover vulnerabilities in a company's website or services. By including a `security.txt` file, companies demonstrate a proactive approach to cybersecurity, making it easier for researchers to report security issues responsibly. This helps in the early detection and mitigation of potential threats, enhances the organisation's security posture, and fosters a collaborative relationship with the security community. Having a `security.txt` file ultimately contributes to protecting both the company's assets and its users' data.
                                </p>
                            </td>
                        </tr>
                        <!-- GSB Threats -->
                        <tr v-if="testResults.domainThreatResults">
                            <th  scope="row">
                                <i v-if="testResults.domainThreatResults.isBlockListed == false" class="h3 text-success bi bi-check-circle-fill"></i>
                                <i v-if="testResults.domainThreatResults.isBlockListed == true" class="h3 text-danger bi bi-exclamation-circle-fill"></i>
                            </th>
                            <td class="lead">Domain not found on Google Safe Browsing list</td>
                            <td>
                                <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#Phishing" role="button" aria-expanded="false" aria-controls="Phishing"></i>
                            </td>
                        </tr>
                        <tr class="collapse multi-collapse" id="Phishing">
                            <td colspan="3">
                                <p class="blockquote-footer mt-2">{{ testResults.domainResults }}</p>   
                                <p class="text-muted ms-3">
                                    The Google Safe Browsing list is a security service that identifies and flags websites involved in malicious activities, such as phishing, malware distribution, and other harmful behaviours. If a website is flagged for suspected phishing, it indicates that it has been detected engaging in activities that could lead to social engineering attacks. This often means that emails from the domain have been reported by recipients as phishing attempts. Being listed signals that the organisation has significant cybersecurity shortcomings, which can damage the organisation's reputation and erode user trust.
                                </p>
                            </td>
                        </tr>
                         <!-- Domain Expiry -->
                        <tr>
                        <th scope="row">
                            <i class="h3 text-secondary bi bi-dash-circle-fill"></i>
                        </th>
                        <td class="lead">Domain not expiring within 90 days</td>
                        <td>
                            <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#domainTest1" role="button" aria-expanded="false" aria-controls="domainTest1"></i>
                        </td>
                        </tr>
                        <tr class="collapse multi-collapse" id="domainTest1">
                            <td colspan="3">   
                                <p class="blockquote-footer mt-2">Test not carried out.</p> 
                                <p class="text-muted ms-3">
                                    A domain close to expiration risks being inadvertently lost, which can lead to service interruptions, loss of email and web services, and potential hijacking by malicious actors. Keeping your domain registration well in advance of its expiration date helps ensure continuous operation, protects your brand and reputation, and prevents potential security risks associated with expired domains.
                                </p>
                            </td>
                        </tr>
                        <!-- Domain Lock -->
                        <tr>
                        <th scope="row">
                            <i class="h3 text-secondary bi bi-dash-circle-fill"></i>
                        </th>
                        <td class="lead">Domain transfer lock enabled</td>
                        <td>
                            <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#domainTest2" role="button" aria-expanded="false" aria-controls="domainTest2"></i>
                        </td>
                        </tr>
                        <tr class="collapse multi-collapse" id="domainTest2">
                            <td colspan="3">   
                                <p class="blockquote-footer mt-2">Test not carried out.</p> 
                                <p class="text-muted ms-3">
                                    A domain transfer lock is a security feature that prevents unauthorised transfers of your domain to another registrar. Enabling a domain transfer lock helps safeguard your domain against hijacking attempts, ensuring that it remains under your control. This added layer of protection is crucial for maintaining the integrity and security of your website, protecting your brand, and ensuring uninterrupted access to your online services. Implementing a domain transfer lock is a proactive measure to enhance the overall security of your domain.
                                </p>
                            </td>
                        </tr>
                       
                         <!-- DNSSEC Enabled -->
                         <tr>
                            <th scope="row">
                                <i v-if="testResults.dnssecResults.dnssecEnabled == true" class="h3 text-success bi bi-check-circle-fill"></i>
                                <i v-if="testResults.dnssecResults.dnssecEnabled == false" class="h3 text-danger bi bi-exclamation-circle-fill"></i>
                        </th>
                        <td class="lead">DNSSEC enabled</td>
                        <td>
                            <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#domainTest3" role="button" aria-expanded="false" aria-controls="domainTest3"></i>
                        </td>
                        </tr>
                        <tr class="collapse multi-collapse" id="domainTest3">
                            <td colspan="3">   
                                <p class="blockquote-footer mt-2">{{ testResults.dnssecResults }}</p> 
                                <p class="text-muted ms-3">
                                    DNSSEC (Domain Name System Security Extensions) is a suite of extensions that adds a layer of security to the DNS (Domain Name System) by enabling the verification of DNS responses. It uses cryptographic signatures to ensure that the information received from a DNS query has not been altered in transit. Implementing DNSSEC helps protect your organisation from DNS spoofing and cache poisoning attacks, which can redirect users to malicious sites. By ensuring the authenticity and integrity of DNS responses, DNSSEC enhances your organisation's overall security posture, protects user trust, and maintains the reliability of your online services.
                                </p>
                            </td>
                        </tr>
                        <!-- Meta/Facebook Pixel Detected -->
                        <tr>
                        <th scope="row">
                            <i class="h3 text-secondary bi bi-dash-circle-fill"></i>
                        </th>
                        <td class="lead">Domian not passing data to Meta</td>
                        <td>
                            <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#trackingTest1" role="button" aria-expanded="false" aria-controls="trackingTest1"></i>
                        </td>
                        </tr>
                        <tr class="collapse multi-collapse" id="trackingTest1">
                            <td colspan="3">   
                                <p class="blockquote-footer mt-2">Test not carried out.</p> 
                                <p class="text-muted ms-3">
                                    The Facebook/Meta tracking pixel is a piece of code embedded on websites to collect data on user behaviour for targeted advertising purposes. While it can enhance marketing efforts by providing insights into user interactions, it poses significant privacy concerns. Using this tracking pixel can lead to the collection and sharing of sensitive user data with third parties, potentially violating privacy regulations like GDPR. It can also erode user trust, as visitors may feel their privacy is being compromised. Therefore, the presence of the Facebook/Meta tracking pixel on your website can expose your organisation to legal risks and damage its reputation regarding user privacy.
                                </p>
                            </td>
                        </tr>
                        <!-- TikTok Pixel Detected -->
                        <tr>
                        <th scope="row">
                            <i class="h3 text-secondary bi bi-dash-circle-fill"></i>
                        </th>
                        <td class="lead">Domian not passing data to TikTok</td>
                        <td>
                            <i class="bi bi-chevron-down text-primary float-end" data-bs-toggle="collapse" href="#trackingTest2" role="button" aria-expanded="false" aria-controls="trackingTest2"></i>
                        </td>
                        </tr>
                        <tr class="collapse multi-collapse" id="trackingTest2">
                            <td colspan="3">   
                                <p class="blockquote-footer mt-2">Test not carried out.</p> 
                                <p class="text-muted ms-3">
                                    The TikTok tracking pixel is a snippet of code placed on websites to monitor user interactions and gather data for targeted advertising. While it can provide valuable marketing insights, its use raises significant privacy concerns. Implementing the TikTok tracking pixel can lead to the collection and sharing of personal user data with third parties, potentially breaching privacy regulations like GDPR. This can erode user trust and expose your organisation to legal risks. Consequently, using the TikTok tracking pixel may harm your organisation's reputation and compromise its commitment to user privacy.
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> 
        </div>
        <div v-else class="p-5 m-5 d-flex justify-content-center">
            <div class=" spinner-border text-warning" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>   
    </div>
</div>

</template>




<script>
export default {
  props: ['testResults'],
};
</script>

<style>
</style>