<template>

<div class="footer_bar mt-5">

</div>

  </template>


<style>
.footer_bar {
  /* background-color: #343A40; */
  height: 100px;
}
.spacer {
  background-color: blueviolet;
  min-height: 100%
}
</style>