<template>
    <HeaderBar />
    <div class="container">
        <SearchBar />
    </div>
    <FooterBar />
</template>
  
  
<script>
import FooterBar from '../components/FooterBar';
import HeaderBar from '../components/HeaderBar';
import SearchBar from '../components/SearchBar';
    
    export default {
      name: 'SearchCompany',
      components: {
        HeaderBar,
        FooterBar,
        SearchBar
      },
      
    };
  
</script>
<style>
</style>
    